import React, { useEffect, useRef, useState } from 'react';
import { HEADER } from "@utility/const.js"
import { NavLink, useLocation, useHistory, Redirect } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useDispatch } from 'react-redux';
import ChevronLeft from '@images/svg-icons/carousel-arrow-left.svg';
import ChevronRight from '@svg-icons/chevron-right.svg';
import { LangMap } from '@model/CoursesClass';
import NotifBell from '@svg-icons/header/notifications.svg';
import Search from '@svg-icons/header/search.svg';
import EmptyBagIcon from '@svg-icons/header/empty-cart.svg';
import FillBagIcon from '@svg-icons/header/fill-cart.svg';
import HamburgerMenuIcon from '@svg-icons/header/hamburger-menu.svg';
import ArrowRight5 from '@svg-icons/arrow-right5.svg';
import AccountIcon from '@svg-icons/header/account.svg';
import AccountIconGold from '@svg-icons/header/gold-account.svg'
import StarGold from '@svg-icons/header/gold-star.svg';
import CloseCircleIcon from '@svg-icons/x-icon2.svg';
import useUnreadNotifications from '@hooks/useUnreadNotifications';
import {
	isCartPage,
	printHeaderLink,
	printLink,
	PUBLIC_URLS,
	styledLogUtagView,
	USER_URLS,
	UtagViewPages,
	BYC_ACTIVE_URLS,
} from './link-utils';
import ButtonV2 from '@components/UI/ButtonV2';
import { useTranslation } from 'react-i18next';
import LeonardoIcon from '@images/logo-app/leonardo.svg';
import VSLogo from '@images/logo-app/VS_Learning_gray.svg';
import KEPLRLogo from '@images/logo-app/keplrAcademy_colori.svg';
import ELlogo from '@images/logo-app/EL360_logo.svg';
import { AnimateSharedLayout, motion, useAnimation } from 'framer-motion';
import { fromHexToRgb } from '@utility/gradient-utils';
import { EVOLUTION_PRIMARY_COLOR, HEADER_LEVEL, queryParams } from '@utility/const';
import useLangAuth from '@hooks/useLangAuth';
import { useSelector } from '@hooks/redux';
import useShowEducationalPathsMenuHeader from '@hooks/educationalPaths/useShowEducationalPathsMenuHeader';
import { analyticsOpenModal, getNovelties, getTortonaInfo, setForceAnalytics, toggleModal } from '@redux-actions/';
import clsx from '@utility/clsx';
import { userRoleIsKeplr, userRoleIsVS, fromCamelToCapitalize, getHeaderLogoUrl } from '@utility/Api';
import useModalTypes from '@hooks/useModalByType';
import { useIsEnabledForCollections } from '@hooks/collections/useIsEnabledForCollections';
import { selectHighlightedCourses, selectPrograms } from '../store/selectors/coursesSelectors';
import CloseIconModalRight from './UI/CloseIconModalRight';
import useL1List from '@hooks/useL1List';
import { isUserEl360, hasUserActivePlan, isBycEnabled } from '@utility/userUtility';
import { buildClickDataTrackingObject, DataLayerTracking, PageSections, selectTrackingDataLayerBase } from '@model/TrackingClass';
import useShowBuildYourCareerMenuHeader from '@hooks/buildYourCareer/useShowBuildYourCareerMenuHeader';
import { analyticsPageType, triggerClickObject } from '@utility/analytics-utils';
import { isPersonificationOn } from '../pages_v3/UserGroupSimulation/UserGroupSimulation_PersonificateLogic';
import { useBreakpointBoolean } from "@hooks/createBreakpoint";
import { camelCase } from 'lodash';

export type Props = {
	isModal?: boolean;
	closeModal?: () => void;
	customBackAction?: () => void;
	showBackButton?: boolean;
	isBackButtonWhiteMobile?: boolean;
	notFixed?: boolean;
	level?: string;
	forceNotTransparent?: boolean;
	isBackAbsolute?: boolean;
	isSticky?: boolean;
	inSearchPage?: boolean;
	useSquareButton?: boolean;
	showBorderBottom?: boolean;
};

const Header = (props: Props) => {
	const dispatch = useDispatch();
	const breakpoint = useBreakpointBoolean()

	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	const lang: LangMap = useSelector(state => state.utils.lang);
	const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
	const [l1List, l0list, y, x, loading] = useL1List(null, false);
	const cart = useSelector(state => state.ecommerce.cart);
	const [showEducationalPathsMenu, showEducationalPathsDropdown] =
		useShowEducationalPathsMenuHeader();
	const [showBuildYourCareerMenu] = useShowBuildYourCareerMenuHeader()
	const ecommerceEnable = useSelector(state => state.user.ecommerceEnable);
	const coursesMap = useSelector(state => state.course.coursesMap);
	const isUserEnabledForCollections = useIsEnabledForCollections();
	const isFullUserCompleted = useSelector(state => state.user.isFullUserCompleted);
	const isUserCompleted = useSelector(state => state.user.isUserCompleted);
	const isBycUser = useSelector(state => state.user.isBycUser);
	const isBycOffice = useSelector(state => state.user.isBycOffice);
	const highlightedCourses = useSelector(selectHighlightedCourses);
	const showCollectionsDropdown = useSelector(
		state => !!state.trainingPills?.latestCollectionsCarouselInfo?.collection?.noveltiesByBrand
	);
	const showCollectionsLink = useSelector(
		state =>
			isUserEnabledForCollections &&
			(!state.trainingPills?.latestCollectionsCarouselInfo?.isCompleted || showCollectionsDropdown)
	); //UU-2149
	const modalTypes = useModalTypes();
	const whiteHeader = HEADER.ISWHITE ? (modalTypes.isAnyModalOpen || props.inSearchPage) : false;

	const isAnySidebarOpen =
		modalTypes.cartRightModal.show ||
		modalTypes.notificationsRightModal.show ||
		modalTypes.profileMenu.show ||
		modalTypes.menuRightModal.show ||
		modalTypes.searchMenu.show;
	// const [open, setOpen] = useState(false);
	const [openUser, setOpenUser] = useState(false);
	const backButtonRef = useRef<HTMLButtonElement>(null);
	const backButtonRefDesktop = useRef<HTMLButtonElement>(null);
	const timeOnTraining = useSelector(state => state.course.timeOnTraining);
	const [levelHeader, setLevelHeader] = useState<string>(props.level || HEADER_LEVEL.ONE);
	const [showBackButton, setShowBackButton] = useState<boolean>(false);
	const [hideBackButtonRestricted, setHideBackButtonRestricted] = useState<boolean>(false);
	const [ShowVisionCareModal, setShowVisionCareModal] = useState<boolean>(false);
	const [headerTitle, setHeaderTitle] = useState<string>('');

	const [isTransparent, setIsTransparent] = useState<boolean>(true);

	const [numUnreadNotifs] = useUnreadNotifications();
	const tortonaLanguage: string = useSelector(state => state.cms.infoTortonaLanguage);
	const isLoadingTortona = useSelector(state => state.cms.isLoadingInfoTortona);
	const userProfile = useSelector(state => state.user.userProfile);
	const isPreviewBannerPresent = useSelector(state =>
		state.utils.showCMSPreviewBanner ||
		state.utils.showCollectionPreviewBanner ||
		state.utils.showLabelPreviewBanner
	)



	const urls = [...Object.values(PUBLIC_URLS), ...Object.values(USER_URLS)];
	const langKeys = [
		'SKIP_MAIN_CONTENT',
		'OPEN_NAVIGATION_MENU',
		'CLOSE_NAVIGATION_MENU',
		'GO_TO_LOGIN',
		'GO_BACK',
		'OPEN_SEARCH',
		'MENU',
		'TOGGLE_NOTIFICATION_VISIBILITY',
		'BACK_HEADER',
		...Object.values(urls).map(a => a.NAME),
	] as const;

	const labels = useLangAuth(langKeys);

	/*RETRIEVE DATA FROM STATE*/
	const [isCMSPersonalized, setIsCMSPersonalized] = useState<boolean>(false);
	const stateOfLinks = useSelector(state => state.utils.linksHeaderFromCMS);

	const hideLogin = useSelector(state => state.utils.hideLogin)
	const hideSignup = useSelector(state => state.utils.hideSignup)

	const [showSignUp, setShowSignup] = useState(false);
	const [showLogin, setShowLogin] = useState(false);

	useEffect(() => {
		if (stateOfLinks.length > 0 && !isAuthenticated) {
			setIsCMSPersonalized(true);
		}
	}, [stateOfLinks,isAuthenticated]);

	useEffect(() => {
		setShowLogin(!hideLogin);
		setShowSignup(!hideSignup);
	  }, [hideLogin, hideSignup]);

	/*RETRIEVE DATA FROM STATE*/

	useEffect(() => {
		let levelHeaderTemp = HEADER_LEVEL.ONE;
		let urlHeaderObj = null;

		if (props.level) {
			levelHeaderTemp = props.level;
		} else {
			urls.forEach(urlObj => {
				if (matchPath(location.pathname, { path: urlObj.URL_ROUTER || urlObj.URL, exact: true })) {
					levelHeaderTemp = urlObj.level;
					urlHeaderObj = urlObj;
				}
			});
		}

		setLevelHeader(levelHeaderTemp);
		setShowBackButton(levelHeaderTemp !== HEADER_LEVEL.ONE);
	//setHideBackButtonRestricted(levelHeaderTemp !== HEADER_LEVEL.ONE && true)
	  setHideBackButtonRestricted(window?.history?.length <= 1)

		setHeaderTitle(levelHeaderTemp === HEADER_LEVEL.TITLE ? labels?.[urlHeaderObj?.NAME] : '');

	}, [location, labels]);

	const historyPath = sessionStorage.getItem('historyPath')
	const initialCourseTitle = sessionStorage.getItem('initialCourseTitle')
	const [isUrlStabilized, setIsUrlStabilized] = useState(false);

	useEffect(function checkHistoryFirstPath() {

		const urlParams = new URLSearchParams(window?.location?.search);
		const courseTitle = urlParams.get('courseTitle');

		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		if (history && (history.length === 1 || history.length === 2)) {
			if (!isUrlStabilized && (!historyPath && historyPath !== `${window?.location?.pathname}${window?.location?.search}`)) {
				//Waiting to see if the url changes
				const timer = setTimeout(() => {
					sessionStorage.setItem('historyPath', `${window?.location?.pathname}${window?.location?.search}`)
					sessionStorage.setItem('initialCourseTitle', courseTitle);
					setIsUrlStabilized(true);
				}, isSafari ? 2000 : 1500); //Short delay to make sure the URL is stabilized

				return () => clearTimeout(timer); //Clean timer
			}
		}
	}, [history, isUrlStabilized]);

	const handlesBackButtonActive = () => {
		const currentPath = `${window?.location?.pathname}${window?.location?.search}`;
		const urlParams = new URLSearchParams(window?.location?.search);
		const courseTitle = urlParams.get('courseTitle');
		const decodedInitialCourseTitle = initialCourseTitle ? decodeURIComponent(initialCourseTitle) : null;
		const decodedCurrentCourseTitle = courseTitle ? decodeURIComponent(courseTitle) : null;

		if (!!decodedInitialCourseTitle && !!decodedCurrentCourseTitle && decodedCurrentCourseTitle === decodedInitialCourseTitle) {
			return false;
		}

		if (history && history.length >= 1 && historyPath !== currentPath) {
			return true;
		}

		return history
			&& history.length > 1
			&& historyPath !== `${window?.location?.pathname}${window?.location?.search}`
	}
	//const isBackButtonActive = history && history.length > 1 && !props?.isLanding  
	const isBackButtonActive = handlesBackButtonActive();

	const personificationOn = isPersonificationOn()

	const handleScrollHeader = (event, useWindow = false) => {
		const isTransparentTemp = useWindow ? window.scrollY <= 0 : event.target.scrollTop <= 0;
		setIsTransparent(isTransparentTemp);
	};

	useEffect(() => {

		if (!props.forceNotTransparent) {
			// Add this in order to prevent LEON-5778 and LEON-5817
			// That is caused by a fix introduced for LEON-3438
			if (personificationOn || isPreviewBannerPresent) {
				const html = document?.documentElement
				if (html && html.style) html.style.overflowX = 'visible'
			}
			let scroller = window;
			const eventScroll = event => handleScrollHeader(event, true);

			scroller?.addEventListener('scroll', eventScroll);

			() => {
				scroller?.removeEventListener('scroll', eventScroll);
			};
		}
	}, [location]);

	const closeMenus = e => {
		const className = e.target.className.toString();
		if (openUser && e.target.nodeName !== 'svg' && !className.includes('user')) {
			setOpenUser(!openUser);
		}
	};

	const currentPageType = fromCamelToCapitalize(analyticsPageType());
	const notificationModalExists = document.querySelector(".notifications-side-modal");

	const handleClickBackButton = () => {
		window?.sessionStorage.removeItem("Products_ContextId_Array");
		if (props.customBackAction) {
			props.customBackAction();
		} else if (props.isModal) {
			props.closeModal();
		} else {
			if (notificationModalExists) {
				notificationModalExists.nextElementSibling.style.transform = 'translateX(100%) translateZ(0px)';
			}
			buildClickDataTrackingObject("goBack")
			history.goBack();
			dispatch(setForceAnalytics(true))
		}
	};

	const handleClickNotifButton = () => {
		dispatch(toggleModal(false, null, true));
		// if (location.pathname.includes(USER_URLS.NOTIFICATIONS.URL)) {
		//   if (history.length > 2) {
		//     history.goBack();
		//   } else {
		//     history.push('/');
		//   }
		// } else {
		//   history.push(USER_URLS.NOTIFICATIONS.URL);
		// }
		modalTypes.notificationsRightModal?.toggle();
		if (!modalTypes.notificationsRightModal?.show) {
			if (notificationModalExists) {
				notificationModalExists.nextElementSibling.style.transform = '';
			}
			dispatch(analyticsOpenModal('Notification Popin'))
		}

	};

	const handleClickMenuButton = () => {
		modalTypes.menuRightModal?.toggle();
		if (!modalTypes.menuRightModal?.show) {
			dispatch(analyticsOpenModal('Menu Popin'))
		}
	};


	const baseTealiumTracking = useSelector(selectTrackingDataLayerBase);
	const utagViewData: DataLayerTracking = {
		...baseTealiumTracking,
		...UtagViewPages.SEARCH,
	};

	const handleClickSearchButton = () => {
		dispatch(toggleModal(false, null, true));
		if (props.inSearchPage) {
			if (location.state?.useSearchDoubleBack) {
				history.go(-2);
			} else {
				history.goBack();
			}
		} else {
			modalTypes.searchMenu?.toggle();
		}
		// styledLogUtagView('searchModal', utagViewData);
		// window?.utag?.view(utagViewData);
	};

	const handleClickCartButton = () => {
		buildClickDataTrackingObject("mainNav_cart")
		dispatch(toggleModal(false, null, true));
		modalTypes.close();
		timeout && clearTimeout(timeout);
		history.push(USER_URLS.CART.URL);
	};

	const handleClickMenuMButtonCMS = (e: any, href: string) => {
		e.preventDefault()
		document.getElementById(href)?.scrollIntoView({ behavior: "smooth" })
	}

	const handleClickMenuMobileButtonCMS = () => {
		modalTypes.menuRightModal?.toggle();

	};

	const [timeout, setModalTimeout] = React.useState(null);
	// const handleHoverCartButton = () => {
	//   if (!!cartItems?.length && !isCartPage(location.pathname)) {
	//     timeout && clearTimeout(timeout);
	//     setModalTimeout(setTimeout(() => modalTypes.cartRightModal.open(), 1000));
	//   }
	// }

	useEffect(() => {
		if (isCartPage(location.pathname)) {
			modalTypes.close();
			clearTimeout(timeout);
		}
	}, [location.pathname]);

	const controls = useAnimation();
	const [prevNumUnreadNotifs, setPrevNumUnreadNotifs] = useState(null);
	const latestCollectionCarouselInfo = useSelector(
		state => state.trainingPills.latestCollectionsCarouselInfo
	);
	// const userProfile = useSelector(state => state.user.userProfile);

	const programs = useSelector(selectPrograms);
	const userEl360 = isUserEl360(userProfile);
	const isUserPlanActive = hasUserActivePlan(userProfile);
	const showCommercialOfferPage = userEl360 ? programs.length > 0 ? true : false : true;

	const [cidParam, setCidParam] = useState('');
	// const [isFirstChange, setIsFirstChange] = useState(true);
	useEffect(() => {
		if (numUnreadNotifs > 0 && numUnreadNotifs > prevNumUnreadNotifs) {
			controls.start({
				boxShadow: [
					'0 0 0 0 rgba(' + fromHexToRgb(EVOLUTION_PRIMARY_COLOR) + ', 0.7)',
					'0 0 0 10px rgba(' + fromHexToRgb(EVOLUTION_PRIMARY_COLOR) + ', 0)',
					'0 0 0 0 rgba(' + fromHexToRgb(EVOLUTION_PRIMARY_COLOR) + ', 0)',
					'0 0 0 0 rgba(' + fromHexToRgb(EVOLUTION_PRIMARY_COLOR) + ', 0.7)',
					'0 0 0 10px rgba(' + fromHexToRgb(EVOLUTION_PRIMARY_COLOR) + ', 0)',
					'0 0 0 0 rgba(' + fromHexToRgb(EVOLUTION_PRIMARY_COLOR) + ', 0)',
				],
				transition: { duration: 5, times: [0, 0.3, 0.4, 0.6, 0.9, 1] },
			});
		}

		//if not the animation starts at every page location change
		// if (prevNumUnreadNotifs !== null) {
		//   setIsFirstChange(false);
		// }
		setPrevNumUnreadNotifs(numUnreadNotifs);
	}, [numUnreadNotifs]);

	useEffect(() => {
		if (props.isModal || showBackButton) {
			//focus back button mobile if visible
			backButtonRef?.current?.focus();
			//focus back button desktop if visible
			backButtonRefDesktop?.current?.focus();
		}
	}, [props.isModal, showBackButton, hideBackButtonRestricted, location.pathname]);

	const labelL1Map = useSelector(state => state.utils.labelL1Map);

	useEffect(() => {
		//download the latest colletions carousel if not already done or the user language has changed
		if (
			isUserEnabledForCollections &&
			userProfile &&
			latestCollectionCarouselInfo?.language !== userProfile?.preferredLang &&
			!latestCollectionCarouselInfo?.isLoading &&
			Object.keys(labelL1Map)?.length > 0
		) {
			dispatch(getNovelties({ language: userProfile.preferredLang, variant: 'carousel' }));
		}
	}, [isUserEnabledForCollections, labelL1Map, userProfile?.preferredLang]);

	useEffect(() => {
    if (coursesMap) {
      let showModal = Object.values(coursesMap).some(course => course.level0 === "vision_care");
      setShowVisionCareModal(showModal);
  }
  }, [coursesMap]);


	useEffect(() => {
		if (
			isUserEnabledForCollections &&
			userProfile &&
			tortonaLanguage !== userProfile?.preferredLang &&
			!isLoadingTortona
			// Object.keys(labelL1Map)?.length > 0
		) {
			dispatch(getTortonaInfo());
		}
	}, [isUserEnabledForCollections, userProfile?.preferredLang]);

	useEffect(() => {
		//let cidParam;
		if (window.location.search.includes(queryParams.CID)) {
			//cidParam = window.location.search;
			setCidParam(window.location.search);
		}
		// console.log(
		//   'CID: ' + cidParam,
		//   window.location.search,
		//   window.location.search.includes(queryParams.CID)
		// );
	}, []);

	const HeaderButtonExpandable = ({
		label,
		handleClick,
		isOpen,
		className = '',
		'data-element-id': dataElementId,
		showDropdown = true,
	}) => {
		return (
			<ButtonV2
				className={
					'header-v3__button-expandable ' + (isOpen ? 'open ' : '') + (className ? className : '')
				}
				variant="text-btn-no-arrow"
				small
				handleClick={showDropdown ? handleClick : () => {
				}}
				data-element-id={dataElementId}
				to={showDropdown ? undefined : USER_URLS.EDUCATIONAL_PATHS.URL}
			>
				{label}
				{label && showDropdown && <ArrowRight5 className="header-v3__button-expandable-arrow" />}

				{isOpen && (
					<motion.div key={dataElementId} layoutId="tabSelector" className="header-v3__slider" />
				)}
			</ButtonV2>
		);
	};

	const handleClickLogo = () => {
		buildClickDataTrackingObject("mainNav_logo")
		modalTypes.close();
		
		if (location.pathname === USER_URLS.HOMEPAGE.URL) {
			if (window?.location?.search?.includes(queryParams.COURSE_DETAIL) || window?.location?.search?.includes(queryParams.COURSE_DETAIL_TITLE)) {
				return
			}
			return window.location.reload();
		}
	};

	// CONST TO CHECK WHEN SHOW ACTIVE BUILD YOUR CAREER HEADER MENU
	const activeBycHeaderMenuItem = location
		&& location.pathname
		&& location.pathname === USER_URLS.BUILD_YOUR_CAREER.URL
		&& !modalTypes?.isAnyModalOpen

	const bycHeaderMenuClassName = clsx(
		'header-v3__header-link',
		{ 'active': activeBycHeaderMenuItem }
	);
	
	// HANDLE CLICK ON BY MENU ITEM (IF THERE'S A MODAL OPEN, CLOSE IT)
	const handleClickBycMenuItem = () => {
		if (modalTypes?.isAnyModalOpen)
			modalTypes?.close();

		if (location.pathname === USER_URLS.BUILD_YOUR_CAREER.URL) {
			window.location.reload(); // Reload the page if already on the target page
		}
	}

	const handleClickUpcomingMenuItem = () => {
		if (location.pathname === USER_URLS.PROFILE_UPCOMING_EVENTS.URL) {
			window.location.reload(); // Reload the page if already on the target page
		}
	}

	// CONST TO CHECK WHEN SHOW ACTIVE UPCOMING EVENTS HEADER MENU
	const activeUpcomingEventsHeaderMenuItem = location
		&& location.pathname
		&& location.pathname === USER_URLS.PROFILE_UPCOMING_EVENTS.URL
		&& !modalTypes?.isAnyModalOpen

	const upcomingEventsHeaderMenuClassName = clsx(
		'header-v3__header-link',
		{ 'active': activeUpcomingEventsHeaderMenuItem }
	);

	const navLinkLogoClassName = clsx(
		'logo',
		{ 'logo-smaller': isAuthenticated && ecommerceEnable },
		{ 'logo-vs': userRoleIsVS(userProfile?.extRole) }
	);

	// IF not enabled to BYC return 404
	if (window?.location?.pathname?.includes(USER_URLS.BUILD_YOUR_CAREER.URL)) {
		// IF not enabled to BYC || BYC OFFICE return 404
		if (
			(isBycUser !== undefined && isBycOffice !== undefined) &&
			(isBycUser !== null && isBycOffice !== null) &&
			(isBycUser === false && isBycOffice === false) && (showBuildYourCareerMenu=== false)
		) {
			return <Redirect to="/404" />
		}
	}

	return (
    <>
      <header
        className={clsx('header-v3', {
          'is-modal': props.isModal,
          'back-button-white-mobile': props.isBackButtonWhiteMobile,
          'not-fixed': props.notFixed,
          'header-v3--white': whiteHeader,
          ['header-v3__level-' + levelHeader]: levelHeader,
          'background-transparent': isTransparent && !props.forceNotTransparent,
          'background-transparent__border': props.isBackAbsolute,
          'header--opened': modalTypes.isAnyModalOpen,
          'cms-personalized': isCMSPersonalized,
		  'show-border-bottom': props.showBorderBottom,
        })}
        id="mainnav"
        onClick={closeMenus}
      >
        <div className="header-v3__wrapper">
          <div className="header-v3__first">
			<div className="header-v3__first__skip-container">
				<a
					href="#main"
					aria-label={labels?.SKIP_MAIN_CONTENT}
					className="text-btn-no-arrow header-v3__first__skip-main"
					>
					{labels?.SKIP_MAIN_CONTENT}
					</a>
			</div> 

            {(showBackButton || props.isModal) &&
							(isBackButtonActive ? (
								<button
									className="icon-button back-button header-v3__back-button focus-outline mobile"
									title={labels?.GO_BACK}
									aria-label={labels?.GO_BACK}
									onClick={handleClickBackButton}
									ref={backButtonRef}
								>
									<ChevronRight />
								</button>
							) : (
								props.useSquareButton && (
									<button
										className={'button-no-back modal-right__close'}
										onClick={handleClickBackButton}
									>
										<CloseCircleIcon />
									</button>
								)
							))}

						<NavLink
							to={isAuthenticated ? USER_URLS.HOMEPAGE.URL : PUBLIC_URLS.HOMEPAGE.URL}
							className={navLinkLogoClassName}
							activeClassName="active"
							title={lang.HOME_PAGE_NAME_LABEL || t('HOME_PAGE_NAME_LABEL')}
							role="banner"

							onClick={handleClickLogo}
						>
							{getHeaderLogoUrl(userProfile?.logoUrlSvg, isUserCompleted, isFullUserCompleted, isAuthenticated)}

						</NavLink>

            {/* {showCloseButton &&
						 <button className={"button-no-back modal__close"} aria-label={lang.HOME_PAGE_NAME_LABEL} onClick={() => history.push(USER_URLS.HOMEPAGE.URL)}>
						 <Close />
						 }*/}
          </div>
          {/*  <div className="flex icons align-center">
					 {[PUBLIC_URLS.HOMEPAGE.URL, PUBLIC_URLS.SERVICE_PAGE.URL].includes(location.pathname) && !props.isAuthenticated && !props.isModal &&
					 <>
					 <ButtonV2 className="header-v3__button-login desktop" variant="primary" small handleClick={() => cidParam ? history.push(PUBLIC_URLS.LOGIN.URL + cidParam) : history.push(PUBLIC_URLS.LOGIN.URL)}>
					 {t('LOGIN_REGISTER')}
					 </ButtonV2>
					 <button className="icon-button login-icon-button mobile" onClick={() => cidParam ? history.push(PUBLIC_URLS.LOGIN.URL + cidParam) : history.push(PUBLIC_URLS.LOGIN.URL)} aria-label={labels?.GO_TO_LOGIN} >
					 <UserIcon />
					 </button>
					 }
					 </div> */}

          {headerTitle && <span className="header-v3__title-page">{headerTitle}</span>}

					{isAuthenticated && !isCMSPersonalized && (
						<AnimateSharedLayout>
							<div className="header-v3__header-links">
								{/* BUILD YOUR CAREER MENU ITEM */}
								{showBuildYourCareerMenu && (

									<div className={bycHeaderMenuClassName}
									onClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang.BUILD_CAREER_TITLE)); handleClickBycMenuItem()}}>
										{printHeaderLink('BUILD_YOUR_CAREER', lang, false)}

									</div>
								)}
								{/* CERTIFICATE PROGRAMS MENU ITEM */}
								{showEducationalPathsMenu && (!isBycUser && !isBycOffice) && (
									<div className="header-v3__header-link">
										<HeaderButtonExpandable
											handleClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang.EDUCATIONAL_PATHS_TITLE));modalTypes.programsMenu?.toggle()}}
											label={lang?.[USER_URLS.EDUCATIONAL_PATHS.NAME]}
											isOpen={modalTypes.programsMenu.show}
											showDropdown={showEducationalPathsDropdown}
											className={ecommerceEnable ? "two-lines-arrow" : ''}
										/>
									</div>
								)}
								{/* VISION CARE MENU ITEM */}
								{ShowVisionCareModal &&
									<div className="header-v3__header-link">
										<HeaderButtonExpandable
											handleClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang?.VISION_CARE_TITLE));modalTypes.visionCareMenu?.toggle()}}
											label={lang?.VISION_CARE_TITLE}
											isOpen={modalTypes.visionCareMenu.show}
											data-element-id="mainNav_visionCare"
											className={ecommerceEnable ? "two-lines-arrow" : ''}
										/>
									</div>
								}
								{(showCollectionsLink || l0list.length > 0) && (
									<div className="header-v3__header-link">
										{
											/*
											 {!showCollectionsDropdown ? (
											 //Show arrow even when menu is not available
											 <HeaderButtonExpandable
											 handleClick={() => {}}
											 label={lang?.COLLECTIONS_LABEL}
											 isOpen={modalTypes.collectionsMenu.show}
											 data-element-id="mainnav_collections"
											 />
											 */
											highlightedCourses.length == 0 && l0list.length === 0 ? (
												printLink('COLLECTIONS_TAB', lang, '', false, false, true)
											) : (
												<HeaderButtonExpandable
												handleClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang?.COLLECTIONS_LABEL));modalTypes.collectionsMenu?.toggle()}}
													label={lang?.COLLECTIONS_LABEL}
													isOpen={modalTypes.collectionsMenu.show}
												/>
											)
										}
									</div>
								)}
								<div className={upcomingEventsHeaderMenuClassName} onClick={handleClickUpcomingMenuItem}>
									{printLink('PROFILE_UPCOMING_EVENTS', lang)}
								</div>
																{/* ALL CONTENT MENU ITEM */}
																<div className="header-v3__header-link">
									<HeaderButtonExpandable
										handleClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang?.AREAS_OF_EXPERTISE));modalTypes.areaOfExpertiseMenu?.toggle()}}
										label={lang?.AREAS_OF_EXPERTISE}
										isOpen={modalTypes.areaOfExpertiseMenu.show}
										className={ecommerceEnable ? "two-lines-arrow" : ''}
									/>
								</div>
								{/*ecommerceEnable && showCommercialOfferPage && (
									<div className="header-v3__header-link">
										{printLink('COMMERCIAL_OFFER', lang)}
									</div>
								)*/}
							</div>
						</AnimateSharedLayout>
					)}

          {isCMSPersonalized && stateOfLinks.length > 0 && (
            <AnimateSharedLayout>
              <div className="header-v3__header-links">
                {/* <div className="header-v3__header-link"> */}
                {stateOfLinks.map((link, index) => (
                  <a
                    key={index}
                    className="header-v3__header-link"
                    href={'#'}
                    onClick={e => handleClickMenuMButtonCMS(e, link.linkString)}
                  >
                    {link.linkLabel}
                  </a>
                ))}
                {/* </div> */}
              </div>
            </AnimateSharedLayout>
          )}

          <div className="header-v3__icons">
            {!isAuthenticated &&
              !props.isModal &&
              location.pathname !== PUBLIC_URLS.WELCOME.URL && (
                <>
                  {location.pathname !== PUBLIC_URLS.REGISTER.URL &&
                    location.pathname !== PUBLIC_URLS.LOGIN.URL && (
                      <>
                        {showSignUp && (
                          <ButtonV2
                            className="header-v3__button-login desktop-and-tablet-landscape"
                            variant="secondary"
                            small
                            to={PUBLIC_URLS.REGISTER.URL}
                            handleClick={()=>{buildClickDataTrackingObject("register")}}
                          >
                            {t('SIGNUP_CTA')}
                          </ButtonV2>
                        )}
                        {showLogin && (
                          <ButtonV2
                            className="header-v3__button-login"
                            variant="primary"
                            small
                            to={cidParam ? PUBLIC_URLS.LOGIN.URL + cidParam : PUBLIC_URLS.LOGIN.URL}
                            handleClick={()=>{buildClickDataTrackingObject("login")}}
                          >
                            {t('LOGIN_CTA')}
                          </ButtonV2>
                        )}
                      </>
                    )}
                </>
              )}

            {isCMSPersonalized &&
              !breakpoint.isDesktopOrTabletLandscape &&
              (location?.pathname === PUBLIC_URLS.HOMEPAGE.URL ||
                location?.pathname.includes('/content/public')) && (
                <>
                  <button
                    className={`icon-button hamburger-v3 ${
                      modalTypes.menuRightModal.show ? 'open' : ''
                    }`}
                    onClick={handleClickMenuMobileButtonCMS}
                    aria-label={
                      modalTypes.menuRightModal.show
                        ? labels?.CLOSE_NAVIGATION_MENU
                        : labels?.OPEN_NAVIGATION_MENU
                    }
                    data-element-id="mainNav_account"
                  >
                    <HamburgerMenuIcon className="phone-and-tablet-portrait" />
                    {modalTypes.menuRightModal.show && <div className="active-border" />}
                  </button>
                </>
              )}

						{isAuthenticated && (
							<>
								<button
									className="icon-button search-button"
									onClick={()=>{buildClickDataTrackingObject("mainNav_"+camelCase(lang?.SEARCH_LABEL));handleClickSearchButton()}}
									aria-label={labels?.OPEN_SEARCH}
								>
									<Search />
									{((props.inSearchPage && !isAnySidebarOpen) || modalTypes.searchMenu.show) && (
										<div className="active-border" />
									)}
								</button>
								{ecommerceEnable && (
									<button
										className="icon-button cart-button"
										onClick={handleClickCartButton}
										aria-label={
											lang?.OPEN_CART +
											' ' +
											(cart?.orderItem?.length > 0
												? cart?.orderItem?.length + ' ' + lang?.ITEM
												: '')
										}
									>
										{(modalTypes.cartRightModal.show ||
											(isCartPage(location.pathname) && !isAnySidebarOpen)) ?
											<FillBagIcon className='fill-button' /> : <EmptyBagIcon fill="currentColor" />
										}
										{cart?.orderItem?.length > 0 && (
											<div className="icon-ball cart-ball">
												<span>{cart.orderItem.length}</span>
											</div>
										)}
										{(modalTypes.cartRightModal.show ||
											(isCartPage(location.pathname) && !isAnySidebarOpen)) && (
												<div className="active-border" />
											)}
									</button>
								)}
								<button
									className="icon-button bell-button custom-color"
									onClick={()=>{buildClickDataTrackingObject("mainNav_notifications");handleClickNotifButton()}}
									aria-label={labels?.TOGGLE_NOTIFICATION_VISIBILITY + ' ' + numUnreadNotifs}
								>
									<NotifBell />
									{numUnreadNotifs > 0 && (
										<motion.div animate={controls} className="icon-ball bell-ball">
											<span>{numUnreadNotifs}</span>
										</motion.div>
									)}
									{modalTypes.notificationsRightModal.show && <div className="active-border" />}
								</button>
								<button
									className={`icon-button hamburger-v3 ${modalTypes.menuRightModal.show ? 'open' : ''
										}`}
										onClick={()=>{buildClickDataTrackingObject("mainNav_profile");handleClickMenuButton()}}
									aria-label={
										modalTypes.menuRightModal.show
											? labels?.CLOSE_NAVIGATION_MENU
											: labels?.OPEN_NAVIGATION_MENU
									}
								>
									<HamburgerMenuIcon className="phone-and-tablet-portrait" />
									{userEl360 || isUserPlanActive ?
										<div className={userEl360 ? 'el-360' : 'active-plan'}>
											<AccountIconGold />
											<StarGold className={(userEl360 ? 'el-360' : 'active-plan') + "__gold-star"} />
										</div>
										: <AccountIcon className="desktop-and-tablet-landscape" />}
									{modalTypes.menuRightModal.show && <div className="active-border" />}
								</button>
							</>
						)}
					</div>
				</div>
			</header>

      {(showBackButton || props.isModal) &&
        (!props.inSearchPage ? (
          <section
            className={clsx('header-v3__back-row', {
              'header-v3__back-row--absolute': props.isBackAbsolute,
              'header-v3__back-row--is-sticky': props.isSticky,
            })}
          >
					{!hideBackButtonRestricted && isBackButtonActive &&
            <div className="header-v3__back-row-content">
              <ButtonV2
                className="header-v3__back-button-desktop"
                variant="icon-btn"
                title={labels?.GO_BACK}
                ariaLabel={labels?.GO_BACK}
                handleClick={handleClickBackButton}
                data-element-id={currentPageType + '_PreviousPage'}
                small
                //forwardRef={backButtonRefDesktop}
              >
                <ChevronLeft />
              </ButtonV2>
            </div>}
          </section>
        ) : (
          <section
            className={clsx('header-v3__back-row', {
              'header-v3__back-row--absolute': props.isBackAbsolute,
              'header-v3__back-row--is-sticky': props.isSticky,
            })}
          >
            <div className="header-v3__back-row-content">
              <CloseIconModalRight
                onClick={handleClickBackButton}
                squareButtonMobile={true}
                className="header-v3__close-button-square"
                ariaLabel={labels?.GO_BACK}
              />
            </div>
          </section>
        ))}
    </>
  );
};

export default Header;
